<template>
  <div class="login-container">
    <div class="main-container">
      <div class="desc-container">
        <span class="p-title">
          {{ projectInfo.applic_name }}
        </span>
        <p class="desc">{{ projectInfo.company_worth }}</p>
      </div>
      <div class="right-container">
        <div class="content-container">
          <div class="top-container">
            <img :src="projectInfo.upload_domain + projectInfo.applic_logo" />
            <div class="change-box" @click="handleChange">
              <div class="change-text">
                {{ !isAccount ? "切换扫码登录" : "切换账户登录" }}
              </div>
              <img v-if="!isAccount" src="~/@/assets/img/change-code.png" />
              <img v-if="isAccount" src="~/@/assets/img/change-account.png" />
            </div>
          </div>
          <div class="account-login" v-if="!isAccount">
            <div class="login-title">{{ projectInfo.applic_sname }}</div>
            <div class="form">
              <el-form ref="ruleFormRef" :model="form" :rules="rules">
                <el-form-item prop="account">
                  <el-input
                    v-model="form.account"
                    autocomplete="off"
                    placeholder="请输入用户手机号"
                    clearable
                  />
                  <i class="iconfont icon-a-zu2828"></i>
                </el-form-item>
                <el-form-item prop="pass">
                  <el-input
                    v-model="form.pass"
                    type="password"
                    placeholder="请输入账户密码"
                    autocomplete="off"
                    clearable
                  />
                  <i class="iconfont icon-a-zu2829"></i>
                </el-form-item>
              </el-form>
            </div>
            <el-button type="primary" @click="handleLogin"> 登录 </el-button>
          </div>
          <div v-if="isAccount" class="code-login">
            <div class="login-title">{{ projectInfo.applic_sname }}</div>
            <div class="code-container">
              <div id="codeImg"></div>
            </div>
          </div>
          <div class="bottom-container">
            {{ projectInfo.technical_support }}
          </div>
        </div>
      </div>
    </div>
    <div class="login-footer">
      阳光大地蜜乐岛系统 | 版本号：v1.0 | Copyright：成都云驰科技有限公司
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, watch } from "vue";
import { useRouter } from "vue-router";
import cookie from "@/utils/cookie";
import { ElMessage } from "element-plus";
import { FrameApi } from "@/plugins/api.js";

export default {
  setup() {
    /** 路由接口 */
    const router = useRouter();
	
	//全屏
	const screenFull = (e) => {
		if (e.requestFullscreen) {
			e.requestFullscreen();
		} else if (e.mozRequestFullScreen) {
			e.mozRequestFullScreen();
		} else if (e.webkitRequestFullscreen) {
			e.webkitRequestFullscreen();
		} else if (e.msRequestFullscreen) {
			e.msRequestFullscreen();
		}
	};
	
	function full(){
		screenFull(document.documentElement);
	}

    /** 表单验证规则 */
    const rules = {
      account: [
        {
          required: true,
          message: "请输入用户手机号",
          trigger: "blur",
        },
      ],
      pass: [
        {
          required: true,
          message: "请输入账户密码",
          trigger: "blur",
        },
      ],
    };

    /** 表单数据对象 */
    const formDataRef = ref({
      account: "",
      pass: "",
    });

    /** 基本信息 */
    const projectInfo = ref({
      upload_domain: " https://z.ygddzy.cn",
      applic_bg: "/storage/baseinfo/login_bg_a.png",
      logo: "/mld/source/logo-title.png",
      title: "蜜乐岛",
      desc: "全域旅游综合服务平台",
      largeTitle: "阳光大地蜜乐岛",
      support: "由云驰科技提供技术支持",
      captcha_appid: "2031319060",
    });

    /** 表单实例 */
    const ruleFormRef = ref();

    /** 腾讯防水墙实例 */
    const captcha = ref(null);

    /** 防水墙初始化/触发 */
    const handleVerify = (flag) => {
      nextTick(() => {
        try {
          captcha.value = new window.TencentCaptcha(
            projectInfo.value.captcha_appid,
            handleSubmit
          );
          captcha.value.destroy();
        } catch (error) {
          // console.log(error);
        }
        if (flag) {
          captcha.value.show(); // 显示验证码
        }
      });
    };

    /** 点击登录按钮处理 */
    const handleLogin = () => {
      if (ruleFormRef.value) {
        ruleFormRef.value.validate((valid) => {
          if (valid) {
            if (captcha.value) {
              // 显示007腾讯防水墙
              captcha.value.show();
            } else {
              handleVerify(true);
            }
          } else {
            // console.log("error submit!!");
            return false;
          }
        });
      }
    };

    /** 表单数据提交 */
    const handleSubmit = (res) => {
      if (res.ret === 0) {
        const params = {
          account: formDataRef.value.account,
          pass: formDataRef.value.pass,
          appid: res.appid,
          ticket: res.ticket,
          randstr: res.randstr,
        };
        // 调登录接口
        FrameApi.login(params).then((res) => {
          if (res.Code === 200) {
            // 获取用户信息
            getUserInfo();
          } else {
            let msg = res.Message ? res.Message : "登录失败！";
            ElMessage.error(msg);
          }
        });
      }
    };

    /** 获取用户信息 */
    const getUserInfo = () => {
      FrameApi.getUserInfo().then((res) => {
        if (res.Code === 200) {
          ElMessage.success("登录成功！");
          cookie.setCookie("user_name", res.Data.user_name);
          cookie.setCookie("user_tel", res.Data.user_tel);
          cookie.setCookie("user_sex", res.Data.user_sex);
          cookie.setCookie("duty_name", res.Data.duty_name);
		  
		  sessionStorage.setItem('user_name',res.Data.user_name);
		  sessionStorage.setItem('user_tel',res.Data.user_tel);
		  sessionStorage.setItem('user_sex',res.Data.user_sex);
		  sessionStorage.setItem('duty_name',res.Data.duty_name);
		  
          !isAccount.value && router.replace("/");
        } else {
          let msg = res.Message ? res.Message : "获取用户信息失败！";
          ElMessage.error(msg);
        }
      });
    };

    /** 获取基础数据 */
    const getSystemInfo = () => {
      FrameApi.getSystemInfo().then((res) => {
        if (res.Code === 200) {
          projectInfo.value = res.Data;
          cookie.setCookie(
            "project-logo",
            res.Data.upload_domain + res.Data.applic_logo
          );

          const node = document.querySelectorAll("link[rel=icon]");
          node.length &&
            node[0].setAttribute(
              "href",
              projectInfo.value.upload_domain + projectInfo.value.applic_ico
            );
          handleVerify(false);
        } else {
          let msg = res.Message ? res.Message : "获取基础信息失败！";
          ElMessage.error(msg);
        }
      });
    };

    onMounted(() => {
      // 获取基础数据
      getSystemInfo();
	  getQrcodeData();
    });
    const isAccount = ref(true);
    function getQrcodeData() {
      FrameApi.qywxInfo().then((res) => {
        if (res.Code === 200) {
          let data = {
            id: "codeImg",
            appid: res.Data.appid,
            agentid: res.Data.agentid,
            redirect_uri: encodeURIComponent(`${window.location.origin}${window.location.pathname}#/init`),
            // redirect_uri: encodeURIComponent(
            //   `http://vpn.ygddzy.cn:16041/cultural/#/init`
            // ),
            state: res.Data.state,
          };
          window.WwLogin(data);
        } else {
          let msg = res.Message ? res.Message : "获取基础信息失败！";
          ElMessage.error(msg);
        }
      });
    }
    watch(
      () => isAccount.value,
      (data) => {
        if (data) {
          getQrcodeData();
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );
    function handleChange() {
      isAccount.value = !isAccount.value;
    }
    return {
      projectInfo,
      rules,
      form: formDataRef,
      ruleFormRef,
      handleLogin,
      handleChange,
      isAccount,
    };
  },
};
</script>

<style lang="scss">
iframe {
  margin-top: 20px;
}
.login-container {
  .el-form {
    background-color: #fff;
  }
  width: 100%;
  height: 100vh;
  background: url(~@/assets/img/login-bg.png) no-repeat top center;
  background-size: cover !important;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  .login-footer {
    position: absolute;
    bottom: 6px;
    font-size: 14px;
    color: #ccc;
  }
  .code-container {
    width: 300px;
    height: 400px;
  }
  .main-container {
    width: 940px;
    height: 590px;
    background: url(~@/assets/img/login-form-bg.png);
    background-size: cover;
    background-color: var(--text-white-color);
    box-shadow: 0px 10px 80px 0px rgba(0, 72, 32, 0.1);
    border-radius: 10px;
    position: relative;

    .desc-container {
      position: absolute;
      top: 80px;
      left: 50px;
      color: var(--text-white-color);

      .p-title {
        font-size: 22px;
        letter-spacing: 2px;
      }

      .desc {
        font-size: 16px;
        margin-top: 20px;
        letter-spacing: 5px;
      }
    }

    .right-container {
      width: 80%;
      height: 100%;
      position: absolute;
      right: 0;

      .content-container {
        width: 470px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px 0 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .top-container {
          width: 100%;
          display: flex;
          justify-content: space-between;

          img {
            width: 100px;
            margin-top: 10px;
          }
          .change-box {
            width: 152px;
            height: 60px;
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            cursor: pointer;
            img {
              width: 60px;
            }
            .change-text {
              width: 92px;
              height: 24px;
              border-radius: 5px 0px 5px 5px;
              border: solid 1px #ccc;
              font-size: 12px;
              line-height: 24px;
              text-align: center;
              color: #ccc;
              margin-top: 16px;
            }
          }
        }
        .login-title {
          font-size: 26px;
          color: #333333;
          font-weight: 600;
          letter-spacing: 4px;
          text-align: center;
        }
        .code-login {
          .login-title {
            margin-top: 30px;
          }

          .wx-tip {
            margin-top: 14px;
            text-align: center;
            color: #0072f6;
          }
        }
        .account-login {
          height: 310px;
          width: 300px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .form {
            width: 100%;

            .el-input__inner {
              border: 1px solid transparent;
              border-bottom: 1px solid #ebebeb !important;
              border-radius: 0 !important;
              padding: 0 30px 0 0;
              background-color: var(--text-white-color) !important;
              color: #1a1a1a;
            }
            .el-input__inner:-webkit-autofill {
              -webkit-box-shadow: 0 0 0px 1000px var(--text-white-color) inset;
              border: none;
            }
          }

          .el-button {
            width: 100%;
            background-color: #2988f6;
            box-shadow: 0px 5px 5px 0px rgba(0, 114, 246, 0.3);
            border-radius: 23px;
          }

          .el-input__suffix {
            right: 20px;
          }

          .iconfont {
            position: absolute;
            right: 0;
            color: #2988f6;
            font-size: 24px;
            text-shadow: 0px 2px 0px rgba(86, 104, 146, 0.3);
          }
        }
      }
    }

    .bottom-container {
      color: #999999;
      opacity: 0.45;
      font-size: 13px;
    }
  }
}
</style>
